import { THEME_CONFIG } from "~config/THEME_CONFIG.config";

const { grid, phoneMockup } = THEME_CONFIG;
const { gridFullWidth, gridMinWidth } = grid;
const { phoneMaxWidth, phoneMinWidth } = phoneMockup;

/**
 * Generates a CSS clamp function string for responsive sizing.
 * The `clamp` function ensures that a value stays within a specified range,
 * scaling responsively between a minimum and maximum size based on the viewport width.
 * @param minSize - The minimum size (in `rem`) for the responsive value.
 * @param maxSize - The maximum size (in `rem`) for the responsive value.
 * @returns A string representing the CSS `clamp` function with the calculated values.
 *
 * Documentation:
 * https://developer.mozilla.org/en-US/docs/Web/CSS/clamp
 */
export function clampBuilder(minSize: number, maxSize: number) {
  const slope = (maxSize - minSize) / (gridFullWidth - gridMinWidth);
  const yAxisIntersection = -gridMinWidth * slope + minSize;

  return `clamp( ${minSize}rem, ${yAxisIntersection}rem + ${
    slope * 100
  }vw, ${maxSize}rem )`;
}

/**
 * Uses cqw to calculate the clamp value for phone mockup, to make subcomponents responsive based on the container size instead of the viewport size.
 *
 * Documentation:
 * https://developer.mozilla.org/en-US/docs/Web/CSS/container
 * https://vanilla-extract.style/documentation/api/create-container/
 */
export function clampBuilderPhoneMockup(minSize: number, maxSize: number) {
  const slope = (maxSize - minSize) / (phoneMaxWidth - phoneMinWidth);
  const yAxisIntersection = -phoneMinWidth * slope + minSize;

  return `clamp( ${minSize}rem, ${yAxisIntersection}rem + ${
    slope * 100
  }cqw, ${maxSize}rem )`;
}
